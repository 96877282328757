<template>
  <div class="farmuserInfoBox">
    <vue-element-loading
      :active="loading"
      text="دریافت لیست کارمندان مزرعه منطقه شما..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div
      class="farmuserInfo"
      v-for="(item, index) of farmuserList"
      :key="index"
      @click="farmuserSelected(item.id)"
    >
      <h4>{{ item.fullName }} ({{ item.createDate }})</h4>
      <hr />
      <div>
        <h6><span class="arrow"></span>{{ item.codeIdentity }}</h6>
        <h6><span class="arrow"></span>{{ item.phone }}</h6>
      </div>
      <div>
        <h6><span class="arrow"></span>{{ item.stateCity }}</h6>
        <h6 style="font-size: 11px">
          <span class="arrow"></span>{{ item.tel }}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      farmuserList: [],
      loading: false,
      searchFarmusers: null,
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionAgent", ["GetAllAgentFarmusers"]),
    async fetchList() {
      this.loading = true;
      let result = await this.GetAllAgentFarmusers({
        searchName: this.searchFarmusers,
      });
      this.loading = false;
      if (result) {
        result.data.forEach((element, index) => {
          this.farmuserList.push({
            id: element.id,
            userId: element.userId,
            codeIdentity: element.codeIdentity,
            fullName: element.fullName,
            phone: element.phone,
            stateCity: element.stateCity,
            tel: element.tel,
            createDate: element.createDate,
          });
        });
        if(result.data.length > 0){
            this.farmuserSelected(result.data[0].id);
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت لیست کارمندان مزرعه <br /><br />",
          type: "error",
        });
      }
    },
    farmuserSelected(item) {
      this.$emit("selectedFarmuser", item);
    },
  },
  mounted() {
    this.fetchList();
  },
  created() {},
};
</script>

<style scoped>
.farmuserInfoBox {
  height: 80vh;
  width: 18vw;
  overflow-y: auto;
  overflow-x: hidden;
}
.farmuserInfoBox::-webkit-scrollbar {
  width: 8px;
}
.farmuserInfoBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}
.farmuserInfoBox::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}
.farmuserInfoBox::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}
.farmuserInfo {
  width: 95%;
  height: 12vh;
  border: 1px solid #bbb;
  margin: 10px 2.5% 0px 0px;
  border-radius: 6px;
  box-shadow: 0px 0pc 4px -1px #000;
  background-color: whitesmoke;
  cursor: pointer;
}
.farmuserInfo:hover {
  background-color: rgb(127, 255, 133);
}
.farmuserInfo.active {
  background-color: rgb(127, 255, 133);
}

.farmuserInfo h4 {
  float: right;
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin: 0px;
  margin-top: 10px;
}
.farmuserInfo hr {
  width: 90%;
  height: 1px;
  margin: 1% 5% 1% 5%;
  background-color: #f1f1f1;
  float: right;
  clear: both;
}
.farmuserInfo div {
  float: right;
  width: 100%;
  clear: both;
  margin-top: 10px;
}
.farmuserInfo div h6 {
  float: right;
  width: 50%;
  padding-right: 10px;
}
.arrow {
  border-right: 7px solid transparent;
  border-bottom: 8px solid rgb(194, 103, 0);
  float: right;
  margin: 1px 1px 1px 3px;
}
</style>
