<template>
  <div>
    <div class="allFarmusersList">
      <h5>لیست کارمندان زیر مجموعه شما</h5>
      <farmuserList @selectedFarmuser="agentSelectedFarmuser" />
    </div>
    <div class="allFarmuserFarms">
      <h5>لیست زمین های کارمند مزرعه انتخاب شده</h5>
      <farmList
        :farmuserId="farmuserIdSelected"
        @selectedFarm="agentSelectedFarm"
      />
    </div>
    <div class="detailBox">
      <farmDetail :farmId="farmIdSelected" />
    </div>
  </div>
</template>

<script>
import farmuserList from "./subForm/farmuserList.vue";
import farmList from "./subForm/farmList.vue";
import farmDetail from "./subForm/farmDetail.vue";
export default {
  components: {
    farmuserList,
    farmList,
    farmDetail,
  },
  data() {
    return {
      farmuserIdSelected : null,
      farmIdSelected : null,
    };
  },
  computed: {},
  methods: {
    agentSelectedFarmuser(item) {
      this.farmuserIdSelected = item;
    },
    agentSelectedFarm(item) {
      this.farmIdSelected = item;
    },
  },
};
</script>

<style scoped>
.allFarmusersList {
  float: right;
  height: 86vh;
  width: 18vw;
  margin-top: 2vh;
  margin-right: 1vw;
  box-shadow: 0px 0px 3px 1px #3c4b64;
  background-color: #fff;
}
.allFarmusersList h5 {
  text-align: center;
  font-size: 1rem;
  padding: 3px;
  color: #3c4b64;
  border-bottom: 1px dashed #bbb;
}
.allFarmuserFarms {
  float: right;
  height: 86vh;
  width: 26vw;
  margin-right: 1vw;
  margin-top: 2vh;
  box-shadow: 0px 0px 3px 1px #3c4b64;
  background-color: #fff;
}
.allFarmuserFarms h5 {
  text-align: center;
  font-size: 1rem;
  padding: 3px;
  color: #3c4b64;
  border-bottom: 1px dashed #bbb;
}
.detailBox {
  float: right;
  height: 86vh;
  width: 37vw;
  margin-right: 10px;
  margin-top: 2vh;
}
</style>
